import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Layout } from 'layout';

const PrivacyPolicy = ({ data }) => {
  const { markdownRemark } = data;

  return (
    <Layout
      className={
        markdownRemark.fields.slug.includes('/lt/') ? 'have-full-height' : null
      }
      metaTitle={
        markdownRemark.frontmatter.metaInformation.metaTitle ||
        markdownRemark.frontmatter.title
      }
      metaDescription={
        markdownRemark.frontmatter.metaInformation.metaDescription || null
      }
      metaKeywords={
        markdownRemark.frontmatter.metaInformation.metaKeywords || null
      }
      metaImage={
        markdownRemark.frontmatter.metaInformation.metaImage
          ? markdownRemark.frontmatter.metaInformation.metaImage.children[0]
              .fluid.src
          : null
      }
    >
      <div className="page__frame">
        <article className="content content--max-width-784 content--centered">
          <h1>{markdownRemark.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
        </article>
      </div>
    </Layout>
  );
};

PrivacyPolicy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        metaInformation: PropTypes.shape({
          metaTitle: PropTypes.string,
          metaDescription: PropTypes.string,
          metaKeywords: PropTypes.arrayOf(PropTypes.string),
          metaImage: PropTypes.oneOfType([PropTypes.object]),
        }),
        title: PropTypes.string,
      }),
      html: PropTypes.string,
      id: PropTypes.string,
      fields: PropTypes.shape({
        slug: PropTypes.string,
      }),
    }),
  }),
};

export const PrivacyPolicyQuery = graphql`
  query privacyPolicy($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      id
      fields {
        slug
      }
      frontmatter {
        metaInformation {
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 1080, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        title
      }
    }
  }
`;

export default PrivacyPolicy;
